import "./AboutMe.css";
import Container from "react-bootstrap/Container";
import Accordion from "react-bootstrap/Accordion";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ImgPort from "../Sylke_porträt_3_cutted.jpg";
import { useState } from "react";
import {ListGroup, ListGroupItem} from "react-bootstrap";

export default function AboutMe() {
  const [matches, setMatches] = useState(window.innerWidth);
  window.addEventListener("resize", checkSize);

  function checkSize() {
    setMatches(window.innerWidth);
  }

  const about =
      <>
        <p>
          Ich bin Sylke, geboren in Berlin, seit 2016 lebe ich in Bayern, zunächst in Ismaning, im Norden von München
          und
          seit 2024 in Landsberg am Lech.
        </p>
        <p>Ich fühle mich besonders wohl in der Natur, liebe es mich zu bewegen, mag Musik, Tanz und Kultur in
          verschiedensten Facetten und bin gern zusammen mit authentischen Menschen. Mich selbst würde ich als
          zugewandt,
          offen und begeisterungsfähig beschreiben. Ich probiere gerne Neues aus und bin dankbar für alle Erfahrungen,
          die
          ich in meinem Leben schon machen durfte.</p>
        <p>
          Wunderbare 30 Jahre lang habe ich getanzt, Tanz unterrichtet und mit unterschiedlichsten Menschen
          zusammengearbeitet. Durch eine Erkrankung habe ich 2010 die Leidenschaft für Yoga für mich entdeckt. </p>
        <p>Meine Ausbildung zur Yogalehrerin 2014 war für mich die Initialzündung den Yogaweg zu gehen. Yoga ist für
          mich,
          ähnlich wie das Tanzen, ins Spüren zu kommen, in die Verbindung zu mir selbst und zum Außen.
          Yoga ist wie ein ankernder, ehrlicher Freund, der mich durchs Leben begleitet.
        </p>
        <p>Sind alle Dinge, die wir tun oder die wir haben, so wichtig wie wir denken?</p>
        <p>In den vielen Fragen und auch Ablenkungen des Alltags kann uns Yoga mit Klarheit unterstützen. Yoga ist für
          mich,
          immer wieder meinen eigenen Weg zu gehen - Leichtigkeit schaffen, Frische, Freiheit, Kribbeln und Inspiration.
          Im Sinne, das der Weg das Ziel ist, ist es mein Herzenswunsch Euch ein Stück auf Eurem Weg zu begleiten und
          inspirieren.
        </p>
      </>
  return (
    <>
      <section className="aboutSec" id="about">
        <h2 id="aboutHeader">Über mich</h2>
        {matches > 768 && (
          <>
            <Container>
                  <img src={ImgPort} alt="Sylke" className="portrait"/>
            </Container>
            <Container>
              <Row>
                <Col className="colText">
                  {about}
                </Col>
               {/* <Col>
                  <img src={ImgPort} alt="Figure in Forest" className="ForestImg" />
                </Col>*/}
              </Row>
            </Container>
            <Container>
              <Row>
                <Col>
                  <ListGroup>
                    <ListGroupItem>2023 Advanced Teacher Training, 300 Stunden, Sascha Peschke, Patrick Broome Yoga Akademie</ListGroupItem>
                    <ListGroupItem>2021 Fortbildung Yin Yoga, Yoga Sky Berlin</ListGroupItem>
                    <ListGroupItem>2019 Aufbauausbildung Methode und Didaktik, Spirit Yoga Berlin</ListGroupItem>
                    <ListGroupItem>ab 2018 Yoga for Friends in München, Soul Yoga in Garching</ListGroupItem>
                    <ListGroupItem>ab 2016 tätig in der Erwachsenenbildung</ListGroupItem>
                    <ListGroupItem>2015 - 2016 Yogaunterricht und Firmenyoga in Berlin</ListGroupItem>
                    <ListGroupItem>2014 Yogaausbildung, Spirit Yoga Berlin</ListGroupItem>
                    <ListGroupItem>1992 - 2015 tätig im Event- und Kulturmanagement</ListGroupItem>
                    <ListGroupItem>1988 - 2016 Tänzerin, Tanzdozentin</ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col></Col>
              </Row>
            </Container>
          </>
        )}
        {matches < 768 && (
          <>
            <Container>
              <Row>
                {/*<img src={ImgPort} alt="Sylke" className="portrait"/>*/}
                <img className="imgPort-sm" src={ImgPort} alt="Sylke" />
                <Col className="colText">
                  {about}
                </Col>
              </Row>
            </Container>
            <Container>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Fortbildungen</Accordion.Header>
                  <Accordion.Body>
                    <ul className="list">
                      <li>2023 AdvancedTeacherTraining, 300Stunden, SaschaPeschke, Patrick Broome Yoga Akademie</li>
                      <li>2021 Fortbildung Yin Yoga, Yoga Sky Berlin</li>
                      <li>
                        2019 Aufbauausbildung Methode und Didaktik, Spirit Yoga
                        Berlin
                      </li>
                      <li>
                        ab 2018 Yoga for Friends in München, Soul Yoga in
                        Garching
                      </li>
                      <li>ab 2016 tätig in der Erwachsenenbildung</li>
                      <li>
                        2015 - 2016 Yogaunterricht und Firmenyoga in Berlin
                      </li>
                      <li>2014 Yogaausbildung, Spirit Yoga Berlin</li>
                      <li>1992 - 2015 tätig im Event- und Kulturmanagement</li>
                      <li>1988 - 2016 Tänzerin, Tanzdozentin</li>
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Container>
          </>
        )}
      </section>
    </>
  );
}
