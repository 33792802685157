import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Logo from "../Sylke_Logo_A.png";
import "./Navigation.css";

export default function Navigation() {
  return (
    <Navbar
      sticky="top"
      collapseOnSelect
      expand="lg"
      className="navbar"
      // variant="dark"
    >
      <Container>

        <Navbar.Brand href="#home">
          <img className="Logo" src={Logo} alt="logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav className="menu">
            <Nav.Link href="#offer">Angebot</Nav.Link>
            <Nav.Link href="#about">Über mich</Nav.Link>
            <Nav.Link href="#contact">Kontakt</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
