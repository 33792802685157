import "./Contact.css";
import EmailImg3 from "../assets/YogaNatur4.jpeg";
import EmailImg2 from "../assets/Frosch.jpg";
import EmailImg1 from "../assets/YogaHände.jpeg";
import Image from 'react-bootstrap/Image'
import { SiMinutemailer } from "react-icons/si";
import {useState} from "react";




function ContactLink() {
    const [matches, setMatches] = useState(window.innerWidth);
    window.addEventListener("resize", checkSize);

    function checkSize() {
        setMatches(window.innerWidth);
    }
    return (

            <section className="contactSec" id="contact">



                    <h2 id="contactHeader">Kontakt</h2>

                <p>Sende deine Fragen, Wünsche, Anregungen an:</p>

                <div className="containerEmail">
                    <SiMinutemailer className="emailIcon"></SiMinutemailer>
                    <a href= "mailto:kontakt@sylkeheuer.de">kontakt@sylkeheuer.de</a>

                </div>
                {matches > 768 && (
                <div className="gallery">
                    <div className="imgBox">
                        <Image src={EmailImg1} alt="Girl in a jacket" width="300" height="300"></Image>
                  </div>
                    <div className="imgBox">
                    <Image src={EmailImg2} alt="Girl in a jacket" width="300" height="300"></Image>
                  </div>
                    <div className="imgBox">
                    <Image src={EmailImg3} alt="Girl in a jacket" width="300" height="300"></Image>
                  </div>
                </div>

                    )
                        }

                {matches < 768 && (
                    <div className="gallery">
                        <div className="imgBox">
                            <Image src={EmailImg1} alt="Girl in a jacket" width="300" height="300"></Image>
                        </div>
                    </div>
                    )}

            </section>

    );
}

export default ContactLink;