import "./Offer.css";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col';
import Accordion from "react-bootstrap/Accordion";
import OfferImg1 from "../assets/Yoga_Sand_Stone.png";
import OfferImg2 from "../assets/Yoga_Matte.png";
import OfferImg3 from "../assets/YogaNatur.jpeg";
import OfferImg4 from "../assets/Kurse.jpeg";
import OfferImg5 from "../assets/freepik-frau-rumpfbeugehaltung-steht.jpeg";
import {useState} from "react";
import {Route, Routes} from "react-router-dom";
import Privatstunden from "./OfferItems/Privatstunden.jsx";
import Firmenyoga from "./OfferItems/Firmenyoga.jsx";
import YogaNatur from "./OfferItems/YogaNatur.jsx";
import Yogakurs from "./OfferItems/Yogakurs.jsx";
import Popup from "./OfferItems/Popup";
import {LinkContainer} from "react-router-bootstrap";
import Button from "react-bootstrap/Button";
import offerDescription from "./OfferDescription.jsx";
import Image from 'react-bootstrap/Image'


function Offer() {
    const [matches, setMatches] = useState(window.innerWidth);
    window.addEventListener("resize", checkSize);

    function checkSize() {
        setMatches(window.innerWidth);
    }

    function onLinkClick() {
        document.getElementById("offer").scrollIntoView({behavior:"smooth"});
    }
    return (
        <>
            <section className="offerSec" id="offer">
                {matches > 768 && (
                    <>
                        <h4 className="introHeader">{offerDescription[0].title}</h4>
                        {/*<div className="intro">{offerDescription[0].body}</div>*/}
                        <div className="intro">
                            Yoga ist ein 2000 Jahre altes ganzheitliches Übungssystem und weit mehr als nur eine körperliche Praxis. Das Wort Yoga (aus dem indischen Sanskrit) heißt soviel wie „Einheit“, „Verbindung“.
                            <br></br>
                            <br></br>
                            In unserer heutigen Welt kann Yoga Körper, Geist und Seele wieder in Verbindung bringen. In einem schnelllebigen und komplexen Alltag mit vielen Aufgaben und Ablenkungen entlastet Yoga Dich durch Ruhe und Klarheit.
                            <br></br>
                            <br></br>
                            Yoga bietet Dir körperlichen und mentalen Ausgleich zu oft einseitigen und überwiegend sitzenden Tätigkeiten am Bildschirm.
                            <br></br>
                            <br></br>
                            Ein bewußtes Rausnehmen mit Yoga aus Deinen Routinen begleitet Dich zurück zu Dir, Deinem Körper, Deinem Atem, dorthin, wo Du Dich wohlfühlen kannst.
                            <br></br><br></br>
                            Fragst Du Dich ob Yoga überhaupt das Richtige für Dich ist?
                            <br></br><br></br>
                            Du brauchst keine Vorkenntnisse haben oder besonders gelenkig sein. Yoga ist für jeden Körper und jedes Alter geeignet.",
                        </div>
                        <q>
                            Im Sinne, dass der Weg das Ziel ist, ist es mein Herzenswunsch Dich ein Stück auf Deinem Weg zu inspirieren.</q>

                        <h2 id="offerHeader">Angebot</h2>
                        <Row lg={2} className="g-4">
                            <Col>
                            <Card className="cardOfferHeight">
                                <Card.Img className="cardImage" variant="top" src={OfferImg2}/>
                                <Card.Body className="offerBoxes">
                                    <Card.Title>Yoga Privatstunden</Card.Title>
                                    <br></br>
                                    <Card.Text>
                                        Personal Yoga, wo Du Dich wohl fühlst.
                                    </Card.Text>
                                    <LinkContainer to={`/Privatstunden`}>
                                        <Button onClick={onLinkClick} className="btnDetails1">Details</Button>
                                    </LinkContainer>
                                </Card.Body>
                            </Card>
                            </Col>
                            <Col>
                            <Card className="cardOfferHeight">
                                <Card.Img className="cardImage" variant="top"  src={OfferImg3}/>
                                <Card.Body className="offerBoxes">
                                    <Card.Title>Yoga in der Natur</Card.Title>
                                    <br></br>
                                    <Card.Text>
                                        Die Natur ist ein Wohlfühlort. Yoga in der Natur ist ideal zum regenerieren.
                                    </Card.Text>
                                    <LinkContainer to={`/Yoga_in_der_Natur`}>
                                        <Button onClick={onLinkClick}>Details</Button>
                                    </LinkContainer>
                                </Card.Body>
                            </Card>
                            </Col>
                            <Col>
                            <Card className="cardOfferHeight">
                                <Card.Img className="cardImage" variant="top" src={OfferImg1}/>
                                <Card.Body className="offerBoxes">
                                    <Card.Title>Firmenyoga</Card.Title>
                                    <br></br>
                                    <Card.Text>
                                        Yoga regeneriert. Yoga schafft Raum für neue Ideen.
                                    </Card.Text>
                                    <LinkContainer to={`/Firmenyoga`}>
                                        <Button onClick={onLinkClick} className="btnDetails2">Details</Button>
                                    </LinkContainer>
                                </Card.Body>
                            </Card>
                            </Col>
                            <Col>
                            <Card className="cardOfferHeight">
                                <Card.Img className="cardImage" variant="top" src={OfferImg4}/>
                                <Card.Body className="offerBoxes">
                                    <Card.Title>Yogakurse in Ismaning - München
                                    </Card.Title>
                                    <Card.Text>
                                        <br></br>
                                        <strong>Vinyasa Flow Yoga</strong>
                                        <br></br>
                                        vhs Ismaning
                                    </Card.Text>
                                    <LinkContainer to={`/Yogakurs`}>
                                        <Button onClick={onLinkClick} className="btnDetails1">Details</Button>
                                    </LinkContainer>
                                </Card.Body>
                            </Card>
                            </Col>
                            <Col>
                            <Card className="cardOfferHeight">
                                <Card.Img className="cardImage" variant="top" src={OfferImg5}/>
                                <Card.Body className="offerBoxes">
                                    <Card.Title>Yoga in Landsberg am Lech
                                    </Card.Title>
                                    <Card.Text>
                                        <br></br>
                                        <strong>Pop Up Yoga am Papierbach</strong>
                                    </Card.Text>
                                    <LinkContainer to={`/popup`}>
                                        <Button onClick={onLinkClick} className="btnDetails1">Details</Button>
                                    </LinkContainer>
                                </Card.Body>
                            </Card>
                            </Col>
                        </Row>
                    </>
                )}
                {matches < 768 && (
                    <>


                        <h4 className="introHeader">Yoga, wo Du dich wohlfühlst </h4>
                        <div className="intro">
                            <p>Yoga ist ein 2000 Jahre altes ganzheitliches Übungssystem und weit mehr als nur eine
                                körperliche Praxis. Das Wort Yoga (aus dem indischen Sanskrit) heißt soviel wie
                                „Einheit“, „Verbindung“.</p>
                            <p>
                                In unserer heutigen Welt kann Yoga Körper, Geist und Seele wieder in Verbindung bringen.
                                In einem schnelllebigen und komplexen Alltag mit vielen Aufgaben und Ablenkungen
                                entlastet Yoga Dich durch Ruhe und Klarheit.
                            </p>
                            <p>
                                Yoga bietet Dir körperlichen und mentalen Ausgleich zu oft einseitigen und überwiegend
                                sitzenden Tätigkeiten am Bildschirm.
                            </p>
                            <p>
                                Ein bewußtes Rausnehmen mit Yoga aus Deinen Routinen begleitet Dich zurück zu Dir,
                                Deinem Körper, Deinem Atem, dorthin, wo Du Dich wohlfühlen kannst.
                            </p>
                            <p>
                                Fragst Du Dich ob Yoga überhaupt das Richtige für Dich ist?
                            </p>
                            <p>
                                Du brauchst keine Vorkenntnisse haben oder besonders gelenkig sein. Yoga ist für jeden
                                Körper und jedes Alter geeignet.
                            </p>
                            <q>
                                Im Sinne, das der Weg das Ziel ist, ist es mein Herzenswunsch Dich ein Stück auf Deinem Weg zu inspirieren.</q>
                        </div>
                        <h2 id="offerHeader">Angebot</h2>
                        <Image className="offerMobile" src={OfferImg4}></Image>

                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Privatstunden</Accordion.Header>
                                <Accordion.Body>
                                    <strong>Personal Yoga, wo Du Dich wohl fühlst.</strong>
                                    <br/> <br/>
                                    Ich biete individuelle Yogastunden, wo Du Dich wohl fühlst, wo Du auftanken und
                                    entspannen kannst. Yoga als ein Weg zu Dir und zu Deinem Körper - zugeschnitten auf Deine persönlichen Bedürfnisse.
                                    <br></br>
                                    <br></br>
                                    Fragst Du Dich ob Yoga überhaupt das Richtige für Dich ist?
                                    <br></br>
                                    Du brauchst keine Vorkenntnisse haben oder besonders
                                    gelenkig sein. Wenn Du Yoga noch nicht ausprobiert hast,
                                    fangen wir zusammen an und schauen wie Dich Yoga
                                    unterstützen kann. Wenn Du schon Yoga praktizierst ganz
                                    genauso. Im Yoga findest Du das, was Du an diesem Tag, in
                                    diesem Moment brauchst.
                                    <br></br>
                                    Die Stunden finden statt, wo Du Dich wohlfühlst, das kann
                                    Deine Wohnung, Dein Garten oder Dein Arbeitsplatz sein. Bei
                                    geeignetem Wetter gehen wir optional in die Natur - einen
                                    Park, eine Wiese, einen See oder einen Wald.
                                    <br></br>
                                    Du möchtest mit eine:r Freund:in zusammen Yoga machen?
                                    <br></br>
                                    Ich biete Yoga auch für zwei und mehr Personen an. Bitte
                                    kontaktiere mich und wir besprechen die Möglichkeiten.
                                    <br></br>
                                    <a href="mailto:kontakt@sylkeheuer.de?subject=Anfrage Privatyoga">kontakt@sylkeheuer.de</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Yoga in der Natur</Accordion.Header>
                                <Accordion.Body>
                                    Vögelzwitschern, Dein Blick in die schöne ursprüngliche Natur, ins Grüne, aufs Wasser.
                                    Die Luft spüren, Dich dehnen, bewegen. Energie aufnehmen, Ballast loslassen, regenerieren.
                                    <br/>
                                    <br/>
                                    <strong>Isaryoga</strong>
                                    <br/>
                                    <strong>Deine Yogazeit in der Natur.</strong>

                                    <br/>
                                    <br/>

                                    Yoga in den Isarauen Ismaning. Über Deinen Atem, bewußtes Gehen und Yoga verbindest Du Dich mit Deinem Körper und der Natur und regst Deine Sinne an. <br></br>Auftanken und wohlfühlen in kleiner Gruppe.<br></br>
                                    Du benötigst bequeme, wetterangepasste Kleidung und eine Matte.
                                    <br></br>
                                    <br></br>
                                    Mittwochs 18:00 - 19:00 Uhr
                                    <br></br>
                                    <br></br>
                                    Kommende Termine<br></br>
                                    11., 18., 25.September<br></br>
                                    <br></br>
                                    Ab 9.Oktober optional indoor.
                                    <br/>
                                    <br/>
                                    1 Termin 	23,00 Euro<br></br>
                                    3 Termine 	60,00 Euro<br></br>
                                    5 Termine 	90,00 Euro<br></br>
                                    <br></br>
                                    Probetermin 15,00 Euro<br></br>
                                    <br></br>
                                    Unseren Treffpunkt erhältst Du nach Deiner Anmeldung.
                                    <br></br>
                                    Anmeldung bis 24 Stunden vorher:{" "}
                                    <a href="mailto:kontakt@sylkeheuer.de?subject=Anmeldung Isaryoga">kontakt@sylkeheuer.de </a>
                                    <br/>
                                    <br/>
                                    <p class="smallNote">Bei weniger als 3 Anmeldungen oder starkem Regen kann ein Termin abgesagt werden. Optional können wir in einen Raum ausweichen.</p>
                                    <br/>
                                    Du möchtest mit eine:r Freund:in zusammen Yoga in der Natur erleben?
                                    Bitte kontaktiere mich und wir besprechen die Möglichkeiten.
                                    <br></br>
                                    <a href="mailto:kontakt@sylkeheuer.de?subject=Anfrage Yoga in der Natur">kontakt@sylkeheuer.de</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            {/*<Accordion.Item eventKey="3">
                                <Accordion.Header>Yoga trifft Waldbaden in Hohenkammer</Accordion.Header>
                                <Accordion.Body>
                                    Der Wald als regenerativer, ruhiger und dabei erfrischender Ort um Energie aufzutanken ist auch ideal um Yoga zu praktizieren. Mit jedem Atemzug im Wald tust Du Deinem Körper Gutes. Nach nur 30 Minuten Waldbaden profitierst Du ungemein für Deine Gesundheit und Dein Wohlbefinden.
                                    <br></br>
                                    Erfahre Dich und die Natur, schlendernd und langsam genießen wir mit geöffneten Sinnen den Wald. Achtsame Wahrnehmungs- und Yogaübungen lassen uns im Hier & Jetzt verwurzeln und stärken unsere Standhaftigkeit.
                                    <br></br>
                                    <br></br>
                                    Die Termine finden auch bei leichtem Regen statt, im Wald sind wir geschützt. Geeignet für alle, auch ohne Vorkenntnisse.
                                    <br></br>
                                    Claudia Müller ist zertifizierte Waldführerin, Sylke Heuer ausgebildete Yogalehrerin und bieten den Workshop gemeinsam an.
                                    <br></br>
                                    Zusammen mit ihrem Mann hat  Claudia einen Wald in Hohenkammer gepachtet, Dort findest Du dort unterschiedliche Veranstaltungen rund um das Thema Wald.
                                    <br></br>
                                    <a href="https://www.waldbaden-bayern.com" rel="noreferrer" target="_blank">waldbaden-bayern.com</a>
                                    <br></br>
                                    <br></br>
                                    Wir freuen uns mit Dir gemeinsam die Energie des Waldes in Verbindung mit Yoga zu erfahren.
                                    <br></br>
                                    <br></br>
                                    Termin: 8.Oktober 2023
                                    <br></br>
                                    Zeit: 13.00 -15.30Uhr
                                    <br></br>
                                    Kosten: 59,00 Euro
                                    <br></br>
                                    Ort: 85411 Hohenkammer bei Freising
                                    <br></br>
                                    <br></br>
                                    Anmeldung:{" "}
                                    <a href="mailto:kontakt@sylkeheuer.de?subject=Anmeldung Waldbaden">kontakt@sylkeheuer.de </a>
                                    <br></br>
                                    <br></br>
                                </Accordion.Body>
                            </Accordion.Item>*/}
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>Firmenyoga</Accordion.Header>
                                <Accordion.Body>
                                    <strong>
                                        Yoga regeneriert. Yoga schafft Raum für neue Ideen.
                                    </strong>
                                    <br/> <br/>
                                    Im getakteten Arbeitstag eine stärkende Pause machen? Wieder
                                    „frei“ sein im Kopf und dabei dem Körper etwas Gutes tun?
                                    Den Geist durchatmen lassen?
                                    <br></br>
                                    <br></br>
                                    Kopflastiges Arbeiten braucht einen Gegenpol. Eine bewegte
                                    Pause mit Yoga regeneriert und unterstützt Ihre Mitarbeiter
                                    dabei.
                                    <br></br>
                                    <br></br>
                                    Yogastunden sind individuell für Ihre Mitarbeiter oder in einer Gruppe möglich. Gerne erstelle ich Ihnen ein Angebot oder berate Sie in einem persönlichen Gespräch. Bitte nehmen Sie Kontakt mit mir auf.
                                    <br></br>
                                    <a href="mailto:kontakt@sylkeheuer.de?subject=Anfrage Firmenyoga">kontakt@sylkeheuer.de</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>Yogakurs in Ismaning - München</Accordion.Header>
                                <Accordion.Body>
                                    <strong>Vinyasa Flow Yoga</strong>
                                    <br /> <br />
                                    Vinyasa Flow Yoga ist eine Form des Hatha Yoga und basiert auf der Verbindung von Atem und Bewegung. Die Körperhaltungen (Asanas) werden fließend miteinander verbunden, dabei werden je nach Intention der Praxis die einzelnen Asanas länger oder kürzer gehalten. Muskeln, Gelenke und Faszien werden in der Yogapraxis gleichermaßen angesprochen. Anders als im Sport geht es im Yoga darum, Körper und Geist in Einklang zu bringen und so ein Gefühl der Ausgeglichenheit und Entspannung zu erschaffen.
                                    Die Teilnahme ist mit und ohne Vorkenntnisse möglich. Bitte bequeme Sportkleidung und möglichst eigene Matte mitbringen.  <br></br><br></br>
                                    <strong>25.09.2024 - 22.01.2025</strong>
                                    <br></br>
                                    Mittwochs 11:45 - 13:00 Uhr
                                    <br></br>
                                    <br></br>
                                    Gebühr: 112,50 Euro
                                    <br></br>
                                    <br></br>
                                    Kursnummer: A3032C
                                    <br></br>
                                    <br></br>
                                    Anmeldung online{" "}
                                    <a href="https://www.vhs-nord.de/kurssuche/kurs/Vinyasa-Flow-Yoga/A3032C" rel="noreferrer" target="_blank">hier</a>
                                    <br></br>oder per Email{" "}
                                    <a href="mailto:info@vhs-nord.de?subject=Anmeldung A3032C Sylke Heuer"> info@vhs-nord.de</a>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="6">
                                <Accordion.Header>Yoga in Landsberg am Lech</Accordion.Header>
                                <Accordion.Body>
                                    <strong>
                                        Pop Up Yoga am Papierbach
                                    </strong>
                                    <br/> <br/>
                                    Du hast Lust auf Yoga in Deiner unmittelbaren Nachbarschaft?
                                    <br></br>
                                    Oder magst mal ins neue Viertel von Landsberg schnuppern?
                                    <br></br>
                                    <br></br>
                                    Wir nutzen leerstehende Räume im neu entstandenen Papierbachviertel.
                                    <br></br>
                                    <br></br>
                                    Vinyasa Flow Yoga
                                    <br></br>
                                    Wir üben fliessend, kräftigend, ausgleichend.
                                    <br></br>
                                    <br></br>
                                    Dienstag ab 10.September
                                    <br></br>
                                    <br></br>
                                    17.30 After Work - ausgeglichen den Tag ausklingen lassen
                                    <br></br><br></br>
                                    Du benötigst bequeme, bei Bedarf warme Kleidung und eine Matte.
                                    <br></br><br></br>
                                    1 Termin 	18,00 Euro<br></br>
                                    3 Termine 	50,00 Euro<br></br>
                                    5 Termine 	80,00 Euro<br></br>
                                    <br></br>
                                    <br></br>
                                    Probetermin 15,00 Euro
                                    <br></br><br></br>
                                    Unseren Treffpunkt erhältst Du bei Deiner Anmeldung.
                                    <br></br>
                                    <br></br>
                                    Anmeldung bitte bis 24 Stunden vorher:&nbsp;
                                    <a href="mailto:kontakt@sylkeheuer.de?subject=Anfrage PopUp Yoga">kontakt@sylkeheuer.de</a>
                                    <br></br>
                                    <br></br>
                                    Bei weniger als 4 Anmeldungen kann ein Termin abgesagt werden.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </>
                )}
            </section>
        </>
    );
}

export default function OfferRoutes() {
    return (

        <Routes>
            <Route index element={<Offer/>}/>
            <Route path="Privatstunden" element={<Privatstunden/>}/>
            <Route path="Firmenyoga" element={<Firmenyoga/>}/>
            <Route path="Yoga_in_der_Natur" element={<YogaNatur/>}/>
            <Route path="Yogakurs" element={<Yogakurs/>}/>
            <Route path="Popup" element={<Popup/>}/>
        </Routes>
    );
}
