import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { useState } from "react";
import OfferImg4 from "../../assets/Kurse.jpeg";
import offerDescription from "../OfferDescription.jsx";

export default function Yogakurs() {
    const [matches, setMatches] = useState(window.innerWidth);
    window.addEventListener("resize", checkSize);

    function checkSize() {
        setMatches(window.innerWidth);
    }
    return (
        <>
            <section className="offerSec">
                <h4 className="introHeader">{offerDescription[0].title}</h4>
                <div className="intro">{offerDescription[0].body}</div>
                <h2 id="offer">Angebot</h2>

             <Row lg={2} sm={12} className="g-4">
                        <Col>
                            <Card className="cardOffer">
                                <Card.Body>
                                    <Card.Title>Yogakurse Ismaning - München</Card.Title>
                                    <Card.Subtitle>
                                        Vinyasa Flow Yoga
                                        <br></br>
                                        vhs Ismaning
                                        <br></br>
                                        <br></br>
                                    </Card.Subtitle>
                                    <Card.Text>
                                        <strong>25.09.2024 - 22.01.2025</strong>
                                        <br></br>
                                        Mittwochs 11:45 - 13:00 Uhr
                                        <br></br>
                                        15 Termine
                                        <br></br>
                                        <br></br>
                                        Gebühr: 112,50 Euro
                                        <br></br>
                                        Kursnummer: A3032C
                                        <br></br>
                                        Anmeldung online{" "}
                                        <a href="https://www.vhs-nord.de/kurssuche/kurs/Vinyasa-Flow-Yoga/A3032C" rel="noreferrer" target="_blank">hier</a>
                                        {" "}oder per Email{" "}
                                        <a href="mailto:info@vhs-nord.de?subject=Anmeldung A3032C Sylke Heuer"> info@vhs-nord.de</a>
                                        <br></br>
                                        <br></br>
                                    </Card.Text>
                                </Card.Body>
                            </Card>

                        </Col>
                 {matches > 768 && (
                        <Col>
                            <Card.Img className="cardImageDetail" variant="top" src={OfferImg4} />
                        </Col>
                 )}
             </Row>

                 <Card className="cardOfferDetail">
                     <Card.Body>
                         <Card.Text>
                             Vinyasa Flow Yoga ist eine Form des Hatha Yoga und basiert auf der Verbindung von Atem und Bewegung. Die Körperhaltungen (Asanas) werden fließend miteinander verbunden, dabei werden je nach Intention der Praxis die einzelnen Asanas länger oder kürzer gehalten. Muskeln, Gelenke und Faszien werden in der Yogapraxis gleichermaßen angesprochen. Anders als im Sport geht es im Yoga darum, Körper und Geist in Einklang zu bringen und so ein Gefühl der Ausgeglichenheit und Entspannung zu erschaffen.
                             Die Teilnahme ist mit und ohne Vorkenntnisse möglich. Bitte bequeme Sportkleidung und möglichst eigene Matte mitbringen. <br></br>
                         </Card.Text>
                     </Card.Body>
                 </Card>
                 <LinkContainer to={`/`}>
                     <Button>Zurück</Button>
                 </LinkContainer>
            </section>
        </>
    );
}
