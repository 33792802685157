import CookieConsent from "react-cookie-consent";

export default function Cookies() {


return (
    <CookieConsent>Diese Webseite verwendet Cookies, um Besuchern ein optimales Nutzererlebnis zu bieten. Wenn du die Website weiter nutzt, gehen wir von deinem Einverständnis aus.</CookieConsent>

);
}

/*

export default function Cookies() {
function setCookie(cookieName, cookieValue, numdaystilexpireasinteger) {
    var d = new Date();
    d.setTime(d.getTime() + (numdaystilexpireasinteger*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cookieName+ "=" + cookieValue + ";" + expires + ";path=/";
}

function getCookie(cookieName) {
    var name = cookieName+ "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for(var i = 0; i <ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function showLaw() {
    var x = getCookie("cookieName");  //call cookie to get its value
    if (x != "") {
        ("#lawmsg").remove();
    } else {
        setCookie("cookieName", "cookieValue", 2);
    }
}

return(
    <div id="lawmsg" className="alert alert-info alert-dismissible h6 fade show fixed-bottom" role="alert">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        &nbsp; We use cookies on this website to distinguish you from other users. &nbsp; We use this data to enhance
        your experience and for targeted advertising. &nbsp; By continuing to use this website you consent to our use of
        cookies. &nbsp; For more information, please see our &nbsp;
        <a href="https://info.profilesonly.com" target="_blank">Cookie Policy</a>.
    </div>
)
}*/
