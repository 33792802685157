import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import { useState } from "react";
import OfferImg2 from "../../assets/Yoga_Matte.png";
import offerDescription from "../OfferDescription.jsx";
import {Link} from "react-router-dom";

export default function Privatstunden() {
  const [matches, setMatches] = useState(window.innerWidth);
  window.addEventListener("resize", checkSize);

  function checkSize() {
    setMatches(window.innerWidth);
  }
  return (
    <>
      <section className="offerSec">
        <h4 className="introHeader">{offerDescription[0].title}</h4>
        <div className="intro">{offerDescription[0].body}</div>
        <h2 id="offer">Angebot</h2>

          <Row lg={2} sm={12} className="g-4">
            <Col>
              <Card className="cardOfferHeight">
                <Card.Body>
                  <Card.Title>Yoga Privatstunden</Card.Title>
                  <br></br>
                  <Card.Subtitle>
                    Personal Yoga, wo Du Dich wohl fühlst.
                  </Card.Subtitle>
                  <Card.Text>
                    <br></br>
                    Ich biete individuelle Yogastunden, wo Du Dich wohl fühlst, wo Du auftanken und
                    entspannen kannst. Yoga als ein Weg zu Dir und zu Deinem Körper - zugeschnitten auf Deine persönlichen Bedürfnisse.
                    <br></br>
                    <br></br>
                    Fragst Du Dich ob Yoga überhaupt das Richtige für Dich ist?
                    <br></br>
                    Du brauchst keine Vorkenntnisse haben oder besonders
                    gelenkig sein. Wenn Du Yoga noch nicht ausprobiert hast,
                    fangen wir zusammen an und schauen wie Dich Yoga
                    unterstützen kann. Wenn Du schon Yoga praktizierst ganz
                    genauso. Im Yoga findest Du das, was Du an diesem Tag, in
                    diesem Moment brauchst.
                    <br></br>
                    Die Stunden finden statt, wo Du Dich wohlfühlst, das kann
                    Deine Wohnung, Dein Garten oder Dein Arbeitsplatz sein. Bei
                    geeignetem Wetter gehen wir optional in die Natur - einen
                    Park, eine Wiese, einen See oder einen Wald.
                    <br></br>
                    <Link to={"/Yoga_in_der_Natur"}>Yoga in der Natur</Link>
                    <br></br>
                    <br></br>
                    Du möchtest mit eine:r Freund:in zusammen Yoga machen?
                    <br></br>
                    Ich biete Yoga auch für zwei und mehr Personen an. Bitte
                    kontaktiere mich und wir besprechen die Möglichkeiten.
                    <br></br>
                    <a href="mailto:kontakt@sylkeheuer.de?subject=Anfrage Privatyoga">kontakt@sylkeheuer.de</a>
                  </Card.Text>
                </Card.Body>
              </Card>
              <LinkContainer to={`/`}>
                <Button>Zurück</Button>
              </LinkContainer>
            </Col>
            {matches > 768 && (
            <Col>
              <Card.Img className="cardImageDetail" variant="top" src={OfferImg2} />
            </Col>
            )}
          </Row>
      </section>
    </>
  );
}
