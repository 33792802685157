import React, { useState } from "react";
import { FaArrowCircleUp } from "react-icons/fa";
// import Button from "react-bootstrap/Button";
// import "./ScrollButton.css";
import { Button } from "./ScrollButtonStyles";
import { LinkContainer } from "react-router-bootstrap";


const ScrollButton = () => {
  const [matches, setMatches] = useState(window.innerWidth);
  window.addEventListener("resize", checkSize);

  function checkSize() {
    setMatches(window.innerWidth);
  }
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
		in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
      <>
        {matches > 768 && (
      <LinkContainer to={`/`}>
    <Button>
      <FaArrowCircleUp
        onClick={scrollToTop}
        style={{ display: visible ? "inline" : "none" }}
      />
    </Button>
      </LinkContainer>
        )}
      </>
  );
};

export default ScrollButton;
