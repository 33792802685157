import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import React, { useState } from "react";
import OfferImg5 from "../../assets/PopUp.png";
import offerDescription from "../OfferDescription.jsx";

export default function Popup() {
    const [matches, setMatches] = useState(window.innerWidth);
    window.addEventListener("resize", checkSize);

    function checkSize() {
        setMatches(window.innerWidth);
    }
    return (
        <>
            <section className="offerSec">
                <h4 className="introHeader">{offerDescription[0].title}</h4>
                <div className="intro">{offerDescription[0].body}</div>
                <h2 id="offer">Angebot</h2>

             <Row lg={2} sm={12} className="g-4">
                        <Col>
                            <Card className="cardOffer">
                                <Card.Body>
                                    <Card.Title>Yoga in Landsberg am Lech</Card.Title>
                                    <Card.Subtitle>
                                        Pop Up Yoga am Papierbach
                                        <br></br>
                                        <br></br>
                                    </Card.Subtitle>
                                    <Card.Text>
                                        Du hast Lust auf Yoga in Deiner unmittelbaren Nachbarschaft?
                                        <br></br>
                                        Oder magst mal ins neue Viertel von Landsberg schnuppern?
                                        <br></br>
                                        <br></br>
                                        Wir nutzen leerstehende Räume im neu entstandenen Papierbachviertel.
                                        <br></br>
                                        <br></br>
                                        Vinyasa Flow Yoga
                                        <br></br>
                                        Wir üben fliessend, kräftigend, ausgleichend.
                                        <br></br>
                                        <br></br>
                                        Dienstag ab 10.September
                                        <br></br>
                                        <br></br>
                                        17.30 After Work - ausgeglichen den Tag ausklingen lassen
                                        <br></br><br></br>
                                        Du benötigst bequeme, bei Bedarf warme Kleidung und eine Matte.
                                        <br></br><br></br>
                                        1 Termin 	18,00 Euro<br></br>
                                        3 Termine 	50,00 Euro<br></br>
                                        5 Termine 	80,00 Euro<br></br>
                                        <br></br>
                                        <br></br>
                                        Probetermin 15,00 Euro
                                        <br></br><br></br>
                                        Unseren Treffpunkt erhältst Du bei Deiner Anmeldung.
                                        <br></br>
                                        <br></br>
                                        Anmeldung bitte bis 24 Stunden vorher:&nbsp;
                                        <a href="mailto:kontakt@sylkeheuer.de?subject=Anfrage PopUp Yoga">kontakt@sylkeheuer.de</a>
                                        <br></br>
                                        <br></br>
                                        Bei weniger als 4 Anmeldungen kann ein Termin abgesagt werden.
                                    </Card.Text>
                                </Card.Body>
                            </Card>

                        </Col>
                 {matches > 768 && (
                        <Col>
                            <Card.Img className="cardImageDetail" variant="top" src={OfferImg5} />
                        </Col>
                 )}
             </Row>

                 <Card className="cardOfferDetail">
                     <Card.Body>
                         <Card.Text>
                             Vinyasa Flow Yoga ist eine Form des Hatha Yoga und basiert auf der Verbindung von Atem und Bewegung. Die Körperhaltungen (Asanas) werden fließend miteinander verbunden, dabei werden je nach Intention der Praxis die einzelnen Asanas länger oder kürzer gehalten. Muskeln, Gelenke und Faszien werden in der Yogapraxis gleichermaßen angesprochen. Anders als im Sport geht es im Yoga darum, Körper und Geist in Einklang zu bringen und so ein Gefühl der Ausgeglichenheit und Entspannung zu erschaffen.
                             Die Teilnahme ist mit und ohne Vorkenntnisse möglich. Bitte bequeme Sportkleidung und möglichst eigene Matte mitbringen. <br></br>
                         </Card.Text>
                     </Card.Body>
                 </Card>
                 <LinkContainer to={`/`}>
                     <Button>Zurück</Button>
                 </LinkContainer>
            </section>
        </>
    );
}
