import Card from "react-bootstrap/Card";
// import backgroundImg from "../Sylke_outdoor21.jpeg";
import "./Header.css";
export default function Header() {
  return (
    <Card className="text-dark headerSec">
      {/* <Card.Img src={backgroundImg} alt="Sylke outdoor" /> */}
      {/* <Card.ImgOverlay>
        <Card.Title>Yoga, wo Du Dich wohlfühlst</Card.Title>
      </Card.ImgOverlay> */}
    </Card>
  );
}
