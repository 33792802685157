import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { LinkContainer } from "react-router-bootstrap";
import React, { useState } from "react";
import OfferImg1 from "../../assets/Yoga_Sand_Stone.png";
import offerDescription from "../OfferDescription.jsx";


export default function Firmenyoga() {
  const [matches, setMatches] = useState(window.innerWidth);
  window.addEventListener("resize", checkSize);

  function checkSize() {
    setMatches(window.innerWidth);
  }
  return (
    <>
      <section className="offerSec">
        <h4 className="introHeader">{offerDescription[0].title}</h4>
        <div className="intro">{offerDescription[0].body}</div>
        <h2 id="offer">Angebot</h2>

          <Row lg={2} sm={12} className="g-4">
            <Col>
              <Card className="cardOfferHeight">
                <Card.Body>
                  <Card.Title>Firmenyoga</Card.Title>
                  <br></br>
                  <Card.Subtitle>
                    Yoga regeneriert. Yoga schafft Raum für neue Ideen.
                  </Card.Subtitle>
                  <br></br>

                  <Card.Text>
                    Im getakteten Arbeitstag eine stärkende Pause machen? Wieder
                    „frei“ sein im Kopf und dabei dem Körper etwas Gutes tun?
                    Den Geist durchatmen lassen?
                    <br></br>
                    <br></br>
                    Kopflastiges Arbeiten braucht einen Gegenpol. Eine bewegte
                    Pause mit Yoga regeneriert und unterstützt Ihre Mitarbeiter
                    dabei.
                    <br></br>
                    <br></br>
                    Yogastunden sind individuell für Ihre Mitarbeiter oder in einer Gruppe möglich. Gerne erstelle ich Ihnen ein Angebot oder berate Sie in einem persönlichen Gespräch. Bitte nehmen Sie Kontakt mit mir auf.
                    <br></br>
                    <a href="mailto:kontakt@sylkeheuer.de?subject=Anfrage Firmenyoga">kontakt@sylkeheuer.de</a>
                  </Card.Text>
                </Card.Body>
              </Card>
              <LinkContainer to={`/`}>
                <Button>Zurück</Button>
              </LinkContainer>
            </Col>
            {matches > 768 && (
            <Col>
              <Card.Img className="cardImageDetail" variant="top" src={OfferImg1} />
            </Col>
            )}
          </Row>
      </section>
    </>
  );
}
